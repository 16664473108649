body {
  margin: 0;
  overflow: hidden;
}

.container {
  display: flex;
  height: 100vh;  
  background-color: #000000;
}

.leftPanel, .centerPanel, .rightPanel {
  flex: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftPanel {
  flex-direction: column;
}

.signContainer {
  margin-bottom: 10px;
}

.signButton {
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  transition: font-size 0.3s ease, color 0.3s ease;
}

.signButton:hover {
  font-size: 100px;
  color: rgb(116, 116, 255);
  font-weight: bold;
}

.centerPanel {
  flex-direction: column;
  color: white;
}

.p {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.centerPanel {
  position: relative;
}

.centerImage {
  max-width: 100%;
  max-height: 100%;
}

.linkContainer {
  padding: 10px 20px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.05);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link:hover {
  color: black;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
}

.link {
  text-align: center;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  margin: 10px 0;
  transition: background-color 0.3s ease;
}