.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
  border-radius: 50px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
}

.location {
  font-size: 1.5rem;
  color: white;
  margin: 0;
  margin-top: 10px;
}

.tempWrapper {
  display: flex;
  align-items: center;
}

.tempText {
  font-size: 50px;
  margin-right: 15px;
  color: white;
  margin: 0;
}

.weatherImg {
  width: 7rem;
  height: 7rem;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.infoRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
}

.maxMin {
  font-size: 1.2rem;
  color: white;
  margin: 0 20px 0 0;
}

.humidityText {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: #46b5ff;
  margin: 0;
}